body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Sora", sans-serif;
  background-color: #f4fcfa;
}

@font-face {
  font-family: "Good Times Rg";
  src: url("./fonts/GoodTimesRg-Regular.eot");
  src: url("./fonts/GoodTimesRg-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/GoodTimesRg-Regular.woff2") format("woff2"),
    url("./fonts/GoodTimesRg-Regular.woff") format("woff"),
    url("./fonts/GoodTimesRg-Regular.ttf") format("truetype"),
    url("./fonts/GoodTimesRg-Regular.svg#GoodTimesRg-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.mainimageBox {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.showImageBox canvas {
  width: 100% !important;
  height: 100% !important;
  // background-image: url("/images/3dbg.webp") !important;
  // background-size: cover !important;
  // background-position: center !important;
  // background-repeat: no-repeat !important;
}

.showImageBox div {
  width: 100% !important;
  height: 100% !important;
  // background-image: url("/images/3dbg.webp") !important;
  // background-size: cover !important;
  // background-position: center !important;
  // background-repeat: no-repeat !important;
}

.showImageBoxCollection canvas {
  width: 100px !important;
  height: 100px !important;
}

.showImageBoxCollection div {
  width: 100px !important;
  height: 100px !important;
}

.showImageBoxCollectiontable canvas {
  width: 35px !important;
  height: 35px !important;
}

.showImageBoxCollectiontable div {
  width: 35px !important;
  height: 35px !important;
}

.showImageBoxCollectionCard canvas {
  width: 65px !important;
  height: 65px !important;
}

.showImageBoxCollectionCard div {
  width: 65px !important;
  height: 65px !important;
}

.showImageBoxCollectionView canvas {
  width: 165px !important;
  height: 165px !important;
}

.showImageBoxCollectionView div {
  width: 165px !important;
  height: 165px !important;
}

.showImageBoxNftCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-color: #ccc !important;
  position: relative;
  // border-radius: 20px;
}

.showImageBoxNftCard canvas {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
}

.showImageBoxNftCard div {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
}

.artwork {
  .slick-initialized .slick-slide.slick-current {
    .active {
      transform: scale(1.15);
      transition: all 0.4s ease-in;
    }
  }
  .slick-track {
    padding: 60px 0;
  }
}
.slick-prev {
  left: auto;
  top: -50px;
  right: 40px;
  color: #000;
}
.slick-next {
  left: auto;
  top: -50px;
  right: 5px;
  color: #000;
}
.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  line-height: 1;
  opacity: 0.75;
  color: #c1c1c1;
}
::-webkit-scrollbar {
  display: none;
}
.rotate {
  -webkit-animation: rotate 5s normal linear infinite;
  animation: rotate 5s normal linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.moveTop {
  -webkit-animation: moveTop 5s normal linear infinite;
  animation: moveTop 5s normal linear infinite;
}

@keyframes moveTop {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.moveLeft {
  -webkit-animation: moveLeft 5s normal linear infinite;
  animation: moveLeft 5s normal linear infinite;
}

@keyframes moveLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@media (max-width: 767px) {
  img.logoImg {
    max-width: 130px !important;
  }
  .pagenotfound {
    max-width: 400px;
  }
}

.MuiList {
  display: flex;
  align-items: flex-start;
  .h5 {
    line-height: 0px;
  }
}
.MuiTypography-list {
  display: block;
  text-align: center;
  padding-right: 16px;
}
.bid div {
  position: relative;
}
.bid div:after {
  content: "";
  border-right: 1px solid #000;
  height: 80%;
  right: 17px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.modal_text {
  h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #039be3;
    margin-bottom: 15px;
  }

  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #3d3d3d;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.timing {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #039be3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  label {
    font-weight: bold;
    font-size: 75%;
    line-height: 17px;
    color: #000000;
    padding: 0 5px;
  }

  img {
    margin-left: 5px;
  }
}
.setPrice {
  background: #ffffff;
  border: 1px solid #898989;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 25px;
  height: 150px;
  width: 150px;

  flex-direction: column;
  justify-content: center;

  color: #000000;
  cursor: pointer;

  svg {
    font-size: 35px;
    margin-bottom: 8px;
  }

  img {
    width: 35px;
    margin-bottom: 8px;
  }

  &.active {
    border: 1px solid #039be3;
    color: #039be3;
  }

  p {
    color: #898989;
  }
}
.physicalstyle {
  position: absolute;
  background: #f0b514;
  padding: 2px;
  color: #000;
  border-top-left-radius: 5px;
  padding: 0px 10px;
  border-bottom-right-radius: 5px;
  .textphysical {
    color: #000;
    font-size: 12px;
  }
}
.displayAlignStart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createimganimation {
  animation: createimganimation 10s ease-in-out infinite;
  max-width: 100%;
  width: auto;
}
@keyframes createimganimation {
  0% {
    opacity: 1;
    transform: scale(1.1) translateY(-0.75em);
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1.1);
  }
  // 89% {
  //   opacity: 1;
  //   transform: scale(1.1);
  // }
  100% {
    opacity: 1;
    // transform: translateZ(-1000em);
    transform: scale(1.1) translateY(-0.75em);
  }
}
.gradientbutton {
  color: #fff !important;
  border: none;
  padding: 10px 19px 10px 19px !important;
  background: #000;
  border-radius: 10px;
  border: solid 1px transparent !important;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #7b3e7a, #0c2745);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #050706 inset;
  border-radius: 10px !important;
}

.albumslickbottomslider {
  .slick-initialized .slick-slide.slick-current {
    .active {
      transform: scale(1.15);
      transition: all 0.4s ease-in;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }
  .slick-track {
    padding: 60px 0;
  }
  .slick-prev:before {
    content: "‹";
  }
  .slick-next:before {
    content: "›";
  }
  .slick-prev {
    left: 45% !important;
    top: 100% !important;
    /* bottom: 0% !important; */
    right: 60px !important;
    color: #fff !important;
    background-color: #000 !important;
    border: 2px solid #323232;

    z-index: 999 !important;
    position: absolute;
    &:hover {
      background: #000;
      border: solid 2px transparent !important;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #5248d2, #a92aac) !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #050706 inset !important;
      border-radius: 50px !important;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next {
    position: absolute;
    left: 50%;
    top: 100% !important;
    right: 5px !important;
    color: #fff !important;
    background-color: #000 !important;
    border: 2px solid #323232;

    z-index: 999 !important;
    &:hover {
      background: #000;
      border: solid 2px transparent !important;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #5248d2, #a92aac) !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #050706 inset !important;
      border-radius: 50px !important;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    color: #323232;
    margin-top: -17px;
  }
}

.albumslickbottomslider .slick-track {
  padding: 30px 0 60px !important;
}

.topslickslider {
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    border-radius: 50%;
    background: 0 0;
  }
  .slick-prev:before {
    content: "‹";
  }
  .slick-next:before {
    content: "›";
  }
  .slick-prev {
    left: auto !important;
    bottom: -20px !important;
    top: -40px !important;
    right: 60px !important;
    color: #fff !important;
    z-index: 999 !important;
    background-color: #000 !important;
    border: 2px solid #323232;

    &:hover {
      background: #000;
      border: solid 2px transparent !important;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #5248d2, #a92aac) !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #050706 inset !important;
      border-radius: 50px !important;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next {
    top: -40px !important;
    bottom: -20px !important;
    left: auto !important;
    right: 5px !important;
    color: #fff !important;
    background-color: #000 !important;
    border: 2px solid #323232;
    z-index: 999 !important;
    &:hover {
      background: #000;
      border: solid 2px transparent !important;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #5248d2, #a92aac) !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #050706 inset !important;
      border-radius: 50px !important;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }
}

.colorBoxgred {
  z-index: 0;
  left: 4%;
  top: -5%;
  right: auto;
  width: 400px;
  bottom: auto;
  filter: blur(100px);
  height: 450px;
  opacity: 0.55;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background: rgba(247, 56, 137, 0.25);
  @media screen and (max-width: 760px) {
    background: rgb(0 0 0);
  }
}
.colorBoxgred1 {
  z-index: 0;
  right: 20%;
  top: 13px;
  left: auto;
  width: 400px;
  bottom: auto;
  filter: blur(100px);
  height: 450px;
  opacity: 0.55;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background: rgba(247, 56, 137, 0.25);
}

// .borderShadowBox {
//   padding: 0.5px;
//   border-radius: 5px;
//   background-image: linear-gradient(black, #000000), linear-gradient(-45deg, #18181d 49%, rgb(255 23 53 / 33%) 75%);
//   background-origin: border-box;
//   background-clip: content-box, border-box;
//   box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
// }

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5) !important;
  -webkit-tap-highlight-color: transparent !important;
  // backdrop-filter: blur(4px) !important;
}
.MuiPaginationItem-page.Mui-selected {
  background: linear-gradient(
    93.34deg,
    #ff6f37 6.82%,
    #ff2676 35.9%,
    #b801aa 68.08%,
    #7101bc 101.4%
  ) !important;
  border-radius: 6px;
}

.closeButton {
  top: 0 !important;
  color: #fff !important;
  right: 0 !important;
  position: absolute !important;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50% !important;
  width: 40px !important;
  height: 40px !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer !important;
  color: transparent;
  border: none;
  outline: 0;
  border-radius: 50%;
  background: 0 0;
}

.bgx1 {
  -webkit-animation: rotating 26s linear infinite;
  animation: rotating 26s linear infinite;
}
.bgx2 {
  -webkit-animation: rotating2 19s linear infinite;
  animation: rotating2 19s linear infinite;
}
@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.boxSeller {
  background: rgb(134, 134, 134);
  background: radial-gradient(
    circle,
    rgba(134, 134, 134, 1) 0%,
    rgba(60, 60, 60, 0) 60%
  );
}
.sellerPaginationButton {
  color: #fff !important;
  background-color: #000 !important;
  border: 2px solid #323232 !important;
  cursor: pointer !important;
  z-index: 999 !important;
  padding: 8px !important;
  &:hover {
    background: #000;
    border: solid 2px transparent !important;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #5248d2, #a92aac) !important;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #050706 inset !important;
    border-radius: 50px !important;
  }
}

.dialogBoxHeight {
  min-height: 500px;
}

.cardbuttonbox {
  background: rgba(255, 255, 255, 0.03) !important;
  box-shadow: 0px 0px 0px 3.525px rgba(255, 255, 255, 0.03) !important;
  border-radius: 50px !important;
}

.css-1qzevvg {
  position: relative;
  display: flex;
  height: 40px;
  margin: 1rem auto 0px;
  width: 20%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

/* Create the border for every card, Paper, and Box*/
.borderGraditant {
  background: linear-gradient(
    321.29deg,
    #ffb000 -2.72%,
    #ff564d 23.92%,
    #ff0098 46.65%,
    #5d00c1 63.7%
  );
  border-radius: 30px;
  padding: 3px !important;
}
.backgroudBlack {
  padding: 16px;
  position: relative;
  background: #0c0508;
  border-radius: 10px;
  background-size: cover;
  // background-color: #0c0508;
  background-repeat: no-repeat;
  background-position: center;
}
.backgroudBlack1 {
  padding: 16px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  background-size: cover;
  // background-color: #0c0508;
  background-repeat: no-repeat;
  background-position: center;
}
/* End */

.css-1qzevvg img {
  height: 100%;
  cursor: pointer;
}
.boxButton {
  position: relative;
  padding: 20px 30px;
  margin: 5px;
  display: inline-block;
  font-size: 30px;
  background: #000 !important;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 3px; /* control the border thickness */
    background: rgba(255, 255, 255, 0.13);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
  svg {
    color: #fff !important;
  }
}

.boxButton:hover {
  &::before {
    background: linear-gradient(163.12deg, #9038ff 16.66%, #f73889 88.36%),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.03),
        rgba(255, 255, 255, 0.03)
      );
  }
}
.bannercarousal {
  width: 100%;
  height: 500px;
  margin: 0 auto;
  @media (max-width: 767px) {
    height: 260px;
  }
}
